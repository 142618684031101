import { __rest } from "tslib";
import HomeContactForm from './HomeContactForm/HomeContactForm.svelte';
import { logError } from '../../Shared/logger';
import setupFormData from '../../Shared/form/setup-form-data';
const setupForm = () => {
    try {
        const homeContactFormContainer = document.querySelector('[js-home-form]');
        const { contactFormData } = window;
        if (!contactFormData || !homeContactFormContainer) {
            return;
        }
        const { fields } = contactFormData, restFormData = __rest(contactFormData, ["fields"]);
        const data = setupFormData(fields, restFormData, '[js-home-form-parent]');
        const homeContactForm = new HomeContactForm({
            target: homeContactFormContainer,
            props: {
                data,
            },
        });
    }
    catch (e) {
        logError('Error initiating HomeContactForm [setupForm]', e);
    }
};
export default setupForm;
