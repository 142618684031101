<script lang="ts">
import {Button, Checkbox, Input, NumberInput, PhoneField, Select} from "../../../Components/form";
import {Data, EventDetailInput, EventDetailPhone} from "../../../Components/form/Form.module";
import windowData from "../../../Shared/util/windowData";
import {ValidatorClassType} from "../../../Shared/form/validator/validator.module";
import {onMount} from "svelte";
import UserCookie from "../../../Shared/user-cookie";
import {logError} from "../../../Shared/logger";
import {searchNearestMarketToUser} from "../../../Shared/algolia-nearest-market";
import getValidator from "../../../Shared/form/validator/getValidator";
import getFormData from "../../../Shared/form/getFormData";
import createPayload from "../../../Shared/form/createPayload";
import trackLeadFormSubmit from "../../../Shared/contact-analytics";
import {storeUserCookies} from "../../../Shared/form/service/cookies";
import {scrollToContactFormSection} from "../../../Shared/util/scrollToContactFormSection";
import removeChildren from "../../../Shared/helpers/removeChildren";

type StaticFields = typeof Select | typeof Input | typeof PhoneField | typeof NumberInput;

export let data: Data;
let firstInvalidElementIndex: number | null = null;
let userCountry: string | null = windowData.appSetup.userCountryCodeOrDefault;
let Validator: ValidatorClassType;

$: useSmsConsent = userCountry && payloadData.optionalFormFields?.smsConsent && payloadData.smsConsentSupportedCountries?.map((c) => c.toUpperCase()).includes(userCountry.toUpperCase());

const staticFieldsMap: Record<string, StaticFields> = {
    select: Select,
    textInput: Input,
    phone: PhoneField,
    number: NumberInput
}

const {payloadData, formState} = data;

const { optionalFormFields } = payloadData;

onMount(() => {
    try {
        const getPreferredLocation = async () => {
            const preferredLocation = UserCookie.getPreferredLocation();
            if (preferredLocation) {
                formState.fields.location.value = preferredLocation;
                return;
            }

            searchNearestMarketToUser().then((nearestMarket) => {
                if (!nearestMarket) {
                    return;
                }
                formState.fields.location.value = nearestMarket.id;
            })
        }

        import('../../../Shared/form/validator/validator').then((validator) => {
            Validator = validator.default;
        });

        getPreferredLocation();
    }
    catch (error) {
        logError('Error on HomeContactForm onMount', error);
    }
});

function onInitialize({ detail: { name, value } }: EventDetailInput) {
    formState.fields[name].value = value;
    formState.fields[name].hasBeenValidated = true;
}

function onInitializePhone({ detail: { name, value, countryCode } }: EventDetailPhone) {
    userCountry = countryCode;
    window.contactFormCountry = userCountry;
    onInitialize({ detail: { name, value } });
}

function destroyHandler({ detail: { name } }: { detail: { name: string } }) {
    formState.fields[name] = {
      errorMessage: null,
      hasBeenValidated: false,
      isValid: true,
      value: null,
    };
}

function onBlur({ detail: name }: CustomEvent) {
    firstInvalidElementIndex = null;

    const hasValue = formState.fields[name].value;

    if (hasValue) {
        validateField(name);
    }
}

function onUpdate({ detail: { name, value } }: EventDetailInput) {
    firstInvalidElementIndex = null;
    formState.fields[name].value = value;
    const hasBeenValidatedBefore = formState.fields[name].hasBeenValidated;

    if (hasBeenValidatedBefore) {
        validateField(name);
    }
}

function onUpdatePhone({ detail: { name, value, countryCode } }: EventDetailPhone) {
    userCountry = countryCode;
    window.contactFormCountry = userCountry;
    onUpdate({ detail: { name, value } });
}

const runValidator = () => {

    let visibleOptionalFormFields = { ...(optionalFormFields || {}) };

    if (!useSmsConsent) {
        delete visibleOptionalFormFields['smsConsent']
    }

    return getValidator({
        fields: payloadData.fields,
        Validator,
        optionalFormFields: visibleOptionalFormFields,
    })
}

const validateField = (fieldName: string) => {
    const validator = runValidator()

    const formData = getFormData(formState.fields);
    const fieldValidation = validator.validateField({ formData, fieldName });
    formState.fields[fieldName].hasBeenValidated = true;

    if(!fieldValidation.success) {
        formState.fields[fieldName].errorMessage = fieldValidation.errorMessage;
        formState.fields[fieldName].isValid = false;

        return;
    }

    formState.fields[fieldName].isValid = true;
}

const submitHandler = async () => {
    try {
        formState.submitStatus.isSubmitting = true;

        const validator = runValidator();
        const formStateData = getFormData(formState.fields);

        const validationResult = validator.validateForm(formStateData);

        if (!validationResult.success) {
            const validationErrors = validator.getValidationErrors(validationResult.error);
            for (const [fieldName, fieldErrorObject] of validationErrors) {
                formState.fields[fieldName].errorMessage = fieldErrorObject._errors[0];
                formState.fields[fieldName].isValid = false;
                formState.fields[fieldName].hasBeenValidated = true;
            }

            formState.submitStatus.isSubmitting = false;

            firstInvalidElementIndex = payloadData.fields.findIndex((field) => field.fieldAttributes.required && !formState.fields[field.fieldAttributes.name].isValid);

            return;
        }

        validationResult.data.phoneCountry = userCountry;
        const payload = createPayload(validationResult.data);

        const response = await fetch(payloadData.submitUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            formState.submitStatus = {
                isSubmitted: true,
                isSubmitting: false,
                isSuccessful: false,
            };

            logError('HomeContactForm [#onSubmit]: Lead submit failed', response.statusText);
            return;
        }

        const body = await response.text();

        formState.submitStatus = {
            isSubmitted: true,
            isSuccessful: response.ok,
            isSubmitting: false,
        };

        const formParent: HTMLElement | null = document.querySelector(payloadData.parentElementSelector ?? '');

        if (formParent) {
            const responseFragment = document.createRange().createContextualFragment(body);

            const responseWrapper = document.createElement('div');

            responseWrapper.classList.add('[&_img]:max-w-50', '[&_img]:block', '[&_img]:mx-auto', '[&_p]:mb-4', 'empty:[&_p]:hidden');

            responseWrapper.appendChild(responseFragment);

            removeChildren(formParent, responseWrapper);

            const formContainer = document.querySelector('[js-contact-us-section]');

            if (formContainer) {
                scrollToContactFormSection(formContainer);
            }

            trackLeadFormSubmit(windowData.getLeadFormTrackingDataToSubmit());
        }

        storeUserCookies(validationResult.data);
    } catch (e) {
        formState.submitStatus = {
            isSubmitted: true,
            isSubmitting: false,
            isSuccessful: false,
        };

        logError('HomeContactForm [#onSubmit]: Lead submit failed', e);
    }
};

const componentInitHandler = (fieldType: string) => (fieldType === 'phone' ? onInitializePhone : onInitialize);
const componentChangeHandler = (fieldType: string) => (fieldType === 'phone' ? onUpdatePhone : onUpdate);
</script>

<form class="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2" on:submit|preventDefault={submitHandler}>
    {#each payloadData.fields as field, index (field.fieldAttributes.name)}
        {@const isFocused = firstInvalidElementIndex === index}
        {#if staticFieldsMap[field.type]}
            <svelte:component
                this={staticFieldsMap[field.type]}
                {isFocused}
                fieldState={formState.fields[field.fieldAttributes.name]}
                staticProps={field.fieldAttributes}
                on:init={componentInitHandler(field.type)}
                on:update={componentChangeHandler(field.type)}
                on:destroy={destroyHandler}
                on:blur={onBlur}
            />
        {/if}
    {/each}

    <div class="col-span-full">
        {#if payloadData.termsAndServiceText}
            <p class="mb-6 text-sm leading-normal [&_a]:underline" data-js-terms-of-service>
                {@html payloadData.termsAndServiceText}
            </p>
        {/if}

        {#if payloadData.optionalFormFields?.marketingConsent}
            <Checkbox
                fieldState={formState.fields.marketingConsent}
                staticProps={payloadData.optionalFormFields?.marketingConsent.fieldAttributes}
                on:update={onUpdate}
                on:init={onInitialize}
            />
        {/if}

        {#if useSmsConsent && payloadData.optionalFormFields?.smsConsent}
            <Checkbox fieldState={formState.fields.smsConsent} staticProps={payloadData.optionalFormFields?.smsConsent.fieldAttributes} on:update={onUpdate} on:init={onInitialize} />
        {/if}

        <Button type="submit" dataTestId={payloadData.submitButtonTestId} isSubmitting={formState.submitStatus.isSubmitting}>{payloadData.submitButtonLabel}</Button>

        {#if formState.submitStatus.isSubmitted && !formState.submitStatus.isSuccessful}
            <span class="pt-1 text-xs text-ww-red-1">{payloadData.errorMessage}</span>
        {/if}

        <input type="hidden" id="Form_PhoneCountry" name="Form_PhoneCountry" value={userCountry} />
    </div>
</form>
